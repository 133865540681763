<template>
  <div class="wrapper fadeIn">
    <head-component/>
    <div class="for-whom-block">
      <h4>Центр проектного творчества «Старт-ПРО» запустил проект «Пока все дома». Каждый может стать частью интерактивного обучения от наших партнеров, выполнить интересные задания, провести эксперименты и посмотреть познавательные ролики.</h4>
      <section class="block-courses" v-if="courses">
        <div class="course-item" v-for="(course, index) in courses" :key="index">
          <div class="row align-items-center mb-1">
            <div class="col">
              <h2 class="mb-2"><a href="/">{{course.name }}</a></h2>
              <div class="course-item__text" v-html="course.desc"></div>
            </div>
            <div class="col-md-5">
              <video class="section__front-video" controls controlsList="nodownload">
                <source :src="course.video" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HeadComponent from "../components/HeadComponent";
export default {
  name: "Partners",
  components: {HeadComponent},
  data() {
    return {
      courses: [
        {
          name: 'Какая у меня осанка',
          desc: '<p>В этом ролике вы узнаете, что такое осанка, и, как проверить свою осанку.<br/>Упражнения, показанные в ролике подойдут как детям, так и взрослым.</p>' +
              '<p>Выполняйте упражнения, укрепляйте спину и будьте здоровы!</p>',
          video: '/video/partners/osanka.mp4'
        },
        {
          name: 'Лавовая лампа',
          desc: '<p>В этом ролике вы узнаете о том, как провести эксперимент «Лавовая лампа».</p>' +
              '<p>Каждый сможет создать своими руками декоративный светильник.</p>',
          video: '/video/partners/lamp.mp4'
        },
        {
          name: 'Пальчиковая гимнастика',
          desc: '<p>В этом ролике будут рассмотрены несколько упражнений, которые сделают пальчики ловкими и шустрыми. Пальчиковая гимнастика - это полезный набор упражнений, развивающий мелкую моторику рук. Занимаясь гимнастикой, ребенок улучшит чувство ритма и подготовится к рисованию и письму. </p>',
          video: '/video/partners/fingers.mp4'
        },
        {
          name: 'Рисование Граттаж',
          desc: '<p>В этом ролике вы познакомитесь с техникой рисования «Граттаж». С помощью этой техники можно создать рисунком путём процарапывания пером или острым инструментом бумаги или картона, залитых тушью.</p>' +
              '<p>Для создания рисунка в этой технике вам потребуется: восковые мелки или масляная пастель, черная гуашь, кисточка, лист плотной бумаги или картона. </p>',
          video: '/video/partners/grattag.mp4'
        },
        {
          name: 'Домашний вулкан',
          desc: '<p>В этом ролике вы сможете провести увлекательный эксперимент в домашних условиях. Вы увидите известный опыт «Домашний вулкан».</p>',
          video: '/video/partners/vulkan.mp4'
        },
        {
          name: 'Когда быстрее устают наши мышцы',
          desc: '<p>В этом ролике вы узнаете интересные и полезные факты о мышцах. Кроме того, вы познакомитесь с причинами, по которым наши мышцы быстро устают.</p>',
          video: '/video/partners/muscule.mp4'
        },
        {
          name: 'Создай свою игру',
          desc: '<p>В этом ролике будет рассмотрен способ создания игры для разнообразия досуга. В игру, созданную своими руками, можно играть всей семьей.</p>',
          video: '/video/partners/game.mp4'
        },
        {
          name: 'Моментальный лед',
          desc: '<p>В этом ролике вы увидите эксперимент «Моментальный лёд», для которого вам потребуется только холодильник с морозильной камерой и обычная вода.</p>',
          video: '/video/partners/led.mp4'
        },
        {
          name: 'Первая помощь при обмороке',
          desc: '<p>В этом ролике мы расскажем, как оказать первую помощь при обмороке. Вы узнаете, как привести человека в себя и, какие действия необходимо совершать, чтобы не навредить человеку в состоянии обморока. </p>',
          video: '/video/partners/obmorok.mp4'
        },
        {
          name: 'Как сделать шахматную доску',
          desc: '<p>В этом ролике вы узнаете о том, как создать самодельную шахматную доску. С помощью этой шахматной доски вы сможете устроить уютный день настольных игр.</p>',
          video: '/video/partners/chess.mp4'
        }
      ]
    }
  }
}
</script>

<style scoped>
.section__front-video {
  width: 100%;
}
video {
  border: 1px solid #323232;
}
</style>